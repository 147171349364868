interface DeviceToStringMap {
  SM: string;
  MD: string;
  LG: string;
  [key: string]: string;
}

export const SCREEN_WIDTH: DeviceToStringMap = {
  SM: "375px",
  MD: "768px",
  LG: "1024px",
};

export const DEVICE: DeviceToStringMap = Object.keys(SCREEN_WIDTH).reduce(
  (acc, key: string) => ({
    ...acc,
    [key]: `(min-width: ${SCREEN_WIDTH[key]})`,
  }),
  {} as any
);

export const COLOR = {
  PRIMARY: "#f15c10",
  GRAY: "#E5E5E5",
};
