import React from "react";
import Helmet from "react-helmet";
import {
  Clock,
  CreditCard,
  PersonPlus,
  Plug,
  Phone,
  Envelope,
} from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AppContext from "../../contexts/app";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import HomePromo from "../../components/HomePromo";
import { InquiryFormModal } from "../../components/InquiryForm";
import FeatureCard from "../../components/FeatureCard";
import PageBanner from "../../components/PageBanner";

import bannerBackground from "./banner-background.png";
import { COLOR } from "../../style";

const HomePageAppExampleBanner = () => (
  <div
    style={{
      backgroundImage: `url(${bannerBackground})`,
      backgroundPosition: "left bottom",
      backgroundAttachment: "fixed",
      backgroundSize: "calc(110%)",
      backgroundRepeat: "repeat-y",
      backgroundColor: COLOR.GRAY,
    }}
  >
    <Container
      className="d-flex align-items-center"
      style={{ height: "240px" }}
    >
      <p
        className="ml-auto display-5 font-weight-bold"
        style={{ maxWidth: "60%" }}
      >
        Our platform brings together the best strategies in fast food
        fechnology!
      </p>
    </Container>
  </div>
);

function HomePage() {
  const appContext = React.useContext(AppContext);
  const [inquiryFormVisible, setInquiryFormVisible] = React.useState(false);

  return (
    <>
      <Helmet>
        <title>
          {appContext.state.companyName} - Serve your customers safely &
          securely
        </title>
      </Helmet>
      <Header />
      <HomePromo showinquryForm={() => setInquiryFormVisible(true)} />
      <Container className="text-center my-5">
        <Row>
          <Col md>
            <FeatureCard
              Icon={Clock}
              title="Order Ahead"
              description="Some quick example text to build on the card title and make up the bulk of the card's content."
            />
          </Col>
          <Col md>
            <FeatureCard
              Icon={CreditCard}
              title="Pay Online"
              description="Some quick example text to build on the card title and make up the bulk of the card's content."
            />
          </Col>
          <Col md>
            <FeatureCard
              Icon={PersonPlus}
              title="Collect Or Deliver"
              description="Some quick example text to build on the card title and make up the bulk of the card's content."
            />
          </Col>
        </Row>
      </Container>
      <PageBanner
        title="Control your digital presence!"
        description="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <HomePageAppExampleBanner />
      <Container className="text-center my-5">
        <Row>
          <Col md>
            <FeatureCard
              Icon={Envelope}
              title="Quick Re-Order"
              description="Some quick example text to build on the card title and make up the bulk of the card's content."
            />
          </Col>
          <Col md>
            <FeatureCard
              Icon={Plug}
              title="Manage Store Online"
              description="Some quick example text to build on the card title and make up the bulk of the card's content."
            />
          </Col>
          <Col md>
            <FeatureCard
              Icon={Phone}
              title="Driver Tracking"
              description="Some quick example text to build on the card title and make up the bulk of the card's content."
            />
          </Col>
        </Row>
      </Container>
      <Footer />
      <InquiryFormModal
        show={inquiryFormVisible}
        hideModal={() => setInquiryFormVisible(false)}
      />
    </>
  );
}

export default HomePage;
