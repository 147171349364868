import React from "react";
import Container from "react-bootstrap/Container";
import AppContext from "../../contexts/app";

const links: any = [];

export function Footer() {
  const appContext = React.useContext(AppContext);

  return (
    <footer className="text-muted text-center text-small">
      <Container>
        <p className="mb-1">© 2019 - 2020 {appContext.state.companyName}</p>
        <ul className="list-inline">
          {links.map(({ title, href }: any) => (
            <li className="list-inline-item">
              <a href={href}>{title}</a>
            </li>
          ))}
        </ul>
      </Container>
    </footer>
  );
}

export default Footer;
