import React from "react";
import styled from "styled-components";
import { DEVICE, COLOR } from "../../style";

type ComponentWithChild = {
  children: React.ReactChild | React.ReactChild[];
};

export const HomePromoContainer = styled.div<{ backgroundImage: string }>`
  position: relative;
  padding: 50px 0 0;
  min-height: 460px;
  background-repeat: no-repeat;
  background-position: 20% 50%;
  background-size: cover;
  background-color: ${COLOR.PRIMARY};
  overflow: hidden;
  background-image: url(${(props) => props.backgroundImage});

  @media ${DEVICE.MD} {
    padding: 120px 0 0;
    min-height: 570px;
  }
`;

export const HomePromoInner = styled.div`
  padding: 0 0 0 20px;
  position: relative;
`;

export const HomePromoImageContainer = styled.div`
  position: absolute;
  right: -240px;
  top: 100px;
  width: 321px;

  @media ${DEVICE.SM} {
    right: -230px;
  }

  @media ${DEVICE.MD} {
    right: -100px;
    top: 120px;
    width: 260px;
  }

  @media ${DEVICE.LG} {
    top: 100px;
    right: 83px;
    width: 321px;
  }
`;

export const PromoTitleH1 = styled.h1`
  @media ${DEVICE.MD} {
    font-size: 50px;
  }
`;

export const PromoTitle = ({ children }: ComponentWithChild) => (
  <PromoTitleH1 className="display-5 text-light font-weight-bold mb-3">
    {children}
  </PromoTitleH1>
);

export const PromoSubtitle = ({ children }: ComponentWithChild) => (
  <p className="text-white-50 h3 font-weight-light mb-4">{children}</p>
);
