import React from "react";
import Container from "react-bootstrap/Container";
import { COLOR } from "../../style";

interface PageBannerProps {
  title: string;
  description?: string;
}

export function PageBanner({ title, description }: PageBannerProps) {
  return (
    <div style={{ backgroundColor: COLOR.PRIMARY }}>
      <Container className="text-center text-light py-5">
        <h3>{title}</h3>
        {description && <p className="m-0 p-0 h5 font-weight-light">{description}</p>}
      </Container>
    </div>
  );
}

export default PageBanner;
