import React from "react";
import Modal from "react-bootstrap/Modal";

interface InquiryFormModalProps {
  show: boolean;
  hideModal: Function;
}

const formId = "1FAIpQLSdMGIcMGOdcOi6viNd5VaEaCX-Hie48XJIwUoR4SSYDK3rH0Q";
const formSrc = `https://docs.google.com/forms/d/e/${formId}/viewform?embedded=true`;

export function InquiryForm() {
  return (
    <iframe
      title="Inquiry Form"
      src={formSrc}
      width="100%"
      height="100%"
      frameBorder="0"
      marginHeight={0}
      marginWidth={0}
    >
      Loading…
    </iframe>
  );
}

export function InquiryFormModal({ show, hideModal }: InquiryFormModalProps) {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => hideModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Inquiry Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "90vh" }}>
        <InquiryForm />
      </Modal.Body>
    </Modal>
  );
}

export default InquiryForm;
