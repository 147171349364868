import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import useInterval from "../../hooks/useInterval";
import { backgroundImage, iphoneCheckout } from "./images";
import {
  HomePromoContainer,
  HomePromoInner,
  HomePromoImageContainer,
  PromoTitle,
  PromoSubtitle,
} from "./style";

interface HomePromoProps {
  showinquryForm: Function;
}

export function HomePromo({ showinquryForm }: HomePromoProps) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const images = [iphoneCheckout];

  useInterval(() => {
    if (images.length === 1) {
      return
    }

    const newIndex =
      selectedImageIndex + 1 >= images.length ? 0 : selectedImageIndex + 1;
    setSelectedImageIndex(newIndex);
  }, 10000);

  return (
    <HomePromoContainer backgroundImage={backgroundImage}>
      <Container>
        <HomePromoInner>
          <HomePromoImageContainer>
            <img
              className="bg-dark border-dark"
              style={{ borderRadius: "40px", border: "4px solid" }}
              src={images[selectedImageIndex]}
              alt="Example FastFood Technology app delivered for merchant"
              width="321"
              height="640"
            />
          </HomePromoImageContainer>

          <div
            style={{
              maxWidth: "680px",
              paddingRight: "100px",
              paddingBottom: "50px",
            }}
          >
            <PromoTitle>
              Serve your customers safely &amp; securely
            </PromoTitle>
            <PromoSubtitle>
              Enable contact-free ordering
              <br />
              through your own app
            </PromoSubtitle>

            <Button variant="success" onClick={() => showinquryForm()}>
              Request a Consultation Today
            </Button>
          </div>
        </HomePromoInner>
      </Container>
    </HomePromoContainer>
  );
}

export default HomePromo;
