import React from "react";
import Card from "react-bootstrap/Card";
import { COLOR } from "../../style";

interface FeatureCardProps {
  Icon: Function;
  title: string;
  description: string;
}

export function FeatureCard({ Icon, title, description }: FeatureCardProps) {
  return (
    <Card border="light">
      <div className="mt-4">
        <Icon color={COLOR.PRIMARY} size={56} />
      </div>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default FeatureCard;
