import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { logoBig } from "../../images";
import { Link } from "react-router-dom";

export function Header() {
  return (
    <Navbar expand="lg" variant="light">
      <Container>
        <Link to="/">
          <Navbar.Brand>
            <img alt="logo" src={logoBig} height="30" />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="//merchant.fastfood.technology/">
              Merchant Login
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
